import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma de copa, de 2 a 4 cm de diàmetre que amb el temps torna pla amb el marge dret que forma una mena de vorera més elevada. A l’interior de la copa es troba l’himeni i és de color violaci molt fosc i la superficie externa del bolet també és violàcea aclarint-se cap a la base. Aquesta sembla que té esquames punxegudes. Les espores són rodones, de 9,8-12 micres de diàmetre, amb berrugues llargues i truncades.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      